import React from "react";
import * as Chakra from "@chakra-ui/react";
import { PageProps } from "gatsby";
import { Seo } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "../images/logo.svg";
import { HeadingLarge } from "../components/heading-large";
import { darken } from "@chakra-ui/theme-tools";
import { Link } from "gatsby";

const Index = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Welcome" location={location} />
    <Chakra.Flex
      width={{ base: "100vw", lg: "34vw" }}
      height="100vh"
      bgColor="#FFFFFF"
      position="fixed"
      top="0"
      left="0"
      zIndex="8"
      px="35px"
      alignItems="center"
    >
      <Chakra.Box maxWidth="340px" pt={{ base: "40px", lg: "0" }}>
        <HeadingLarge pb="35px">
          Experimentation
          <br />
          Workbook.
        </HeadingLarge>
        <Chakra.Text
          pb="35px"
          fontSize="25px"
          lineHeight="35px"
          letterSpacing="0.2px"
        >
          Have more confidence in the decisions you make with experimentation.
        </Chakra.Text>
        <Chakra.Text
          pb="35px"
          fontSize="25px"
          lineHeight="35px"
          letterSpacing="0.2px"
        >
          Two-day experimentation short-course.
        </Chakra.Text>
        <Chakra.Button
          backgroundColor="#E6B822"
          color="#000000"
          fontSize="14px"
          letterSpacing="0.11px"
          px="20px"
          fontWeight="400"
          borderRadius="0"
          transition="0.3s"
          as={Link}
          _hover={{
            backgroundColor: darken("#E6B822", 5),
          }}
          _focus={{
            backgroundColor: darken("#E6B822", 8),
          }}
          _active={{
            backgroundColor: darken("#E6B822", 8),
          }}
          to="/start"
        >
          Get Started
        </Chakra.Button>
      </Chakra.Box>
    </Chakra.Flex>
    <Chakra.Box
      top={{ base: "50vh", lg: "0" }}
      d={{ base: "none", lg: "unset" }}
      backgroundColor="black"
      width={{ base: "100vw", lg: "66vw" }}
      height={{ base: "50vh", lg: "100vh" }}
      bgColor="#FFFFFF"
      position="fixed"
      right="0"
      zIndex="8"
    >
      <StaticImage
        src="../images/intro.png"
        alt="A kitten"
        style={{ width: "100%", height: "100%" }}
        placeholder="blurred"
      />
      <Chakra.Flex
        position="absolute"
        height="100%"
        width="100%"
        color="#fff"
        top="0"
        css={{ svg: { width: "472px", height: "242px" } }}
        alignItems="center"
        justifyContent="center"
      >
        <Logo />
      </Chakra.Flex>
    </Chakra.Box>
  </Chakra.Box>
);

export default Index;
